import React from 'react';
import "./Home.css";
import { ErrorBoundary } from '@components/Shared';
import { useEffect } from 'react';
import { useUserContext } from '@components/Shared/UserContext';

const Home = () => {
    const { currentUser, setCurrentUserContext } = useUserContext();

    useEffect(() => {
        // dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
        <ErrorBoundary>
            <div className="home-page-wrapper">
                <div className="home-title">
                    <div>Improve your trading</div>
                    <div>Use AI models to predict future prices</div>
                </div>
                <div className="home-img"></div>
                {/* {currentUser ? (
                    <>
                        <h1>Use AI models to predict future prices</h1>
                        <h1>For traders who wants improve their results</h1>
                    </>
                ) : (
                    <>
                        <h1>In progress</h1>
                    </>
                )} */}
                

            </div>
            
            {/* <Link to='/login'>Login</Link>
            <Link to='/register'>Register</Link> */}
        </ErrorBoundary>
        </>
    );
};

export default Home;
