import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box, Link } from "@mui/material";
import { usePostAccountToken } from '../../client/account'; // Adjust the path as needed
import { GetTokenRequest } from '../../model'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import "./CreateAccount.css"; // Import the CSS module

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const [createAccountData, setCreateAccountData] = useState({
    email: '',
    password: '',
  });

  // Mutation hook for getting account token
  const getTokenMutation = usePostAccountToken({
    // Pass mutation options if needed
    // For example, you can pass onSuccess, onError callbacks here
  });


  const handleCreateAccount = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
  };


  // Function to handle form input changes
  const handleInputChange = (e: any) => {
    const { email, value } = e.target;
    setCreateAccountData((prevData) => ({
      ...prevData,
      [email]: value,
    }));
  };

  return (
    <div className="page">
      <Box className="container">
        <Typography className="title">
          Create Account
        </Typography>
        <div>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Email"
            type="text"
            name="email"
            value={createAccountData.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            value={createAccountData.password}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Repeat password"
            type="password"
            name="password"
            value={createAccountData.password}
            onChange={handleInputChange}
            required
          />
          <div className="action-buttons">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCreateAccount} // Handle the sign-in action here
            >
              Create account
            </Button>
          </div>

        </div>
      </Box>
    </div>
  );
};

export default CreateAccount;
